<template>
    <div>
        <Navigation />
        <div class="panel">
            <h1>User Management</h1>

            <table>
                
            </table>
        </div>
    </div>
</template>


<script>
import Navigation from '@/components/V1/Navigation.vue'

export default {
    name: "Users",
    components: {
        Navigation
    }
} 
</script>

<style scoped>
</style>